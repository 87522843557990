<template>
<div>
  <div class="row justify-content-center py-8 px-8 py-md-36 px-md-0 bgi-size-cover bgi-position-center" style="background-image: url('/assets/media/bipart/banner-urf.svg')">
    <div class="col-md-9">
      <div class="d-flex justify-content-between align-items-md-center flex-column flex-md-row">
        <div class="d-flex flex-column px-0 order-2 order-md-1">
            <a href="#" class="mb-5 max-w-115px">
                <span class="svg-icon svg-icon-full svg-logo-white">
                </span>
            </a>
            <h1 class="display-3 font-weight-boldest order-1 order-md-2">{{$t("FAQCUSTOM.FAQ")}}</h1>
            <div class="w-150px bg-primary my-5 p-1"></div>
            <h2 class="display-4 order-2 mb-5">{{$t("FAQCUSTOM.GLOSSARIO")}}</h2>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-center py-8 px-8 py-md-20 px-md-0 bg-default">
    <div class="col-lg-9 col-xl-9">
      <div class="d-flex flex-column">
        <div class="row align-items-center mt-5">

          <div class="tab-content w-100">
            <div class="row p-5">
              <div class="col-12 mb-5">
                  <div class="card card-custom bg-white mb-3 shadow-sm">
                    <b-button v-b-toggle.collapse-1 class="btn btn-block btn-white text-primary p-10 text-left">
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                       <i class="fas fa-chevron-right text-primary"></i>
                      </span>
                      <span class="h4 ml-5 font-size-bolder">{{$t("FAQCUSTOM.PERCHEPARTECIPARE")}}</span>

                    </b-button>
                    <b-collapse id="collapse-1">
                      <div class="card-body">
                        <div class="row px-5">
                          <div class="col-lg-12 col-sm-12">
                            {{$t("FAQCUSTOM.DESCRIZIONE")}}
                          </div>
                        </div>

                      </div>
                    </b-collapse>
                  </div>
                  <div class="card card-custom bg-white mb-3 shadow-sm">
                    <b-button v-b-toggle.collapse-2 class="btn btn-block btn-white text-primary p-10 text-left">
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                       <i class="fas fa-chevron-right text-primary"></i>
                      </span>
                      <span class="h4 ml-5 font-size-bolder"> {{$t("FAQCUSTOM.QUALITEMIPROGETTI")}}</span>
                    </b-button>
                    <b-collapse id="collapse-2">
                      <div class="card-body">
                        <div class="row px-5">
                          <div class="col-lg-12 col-sm-12">
                            {{$t("FAQCUSTOM.TEMI")}}

                          </div>
                        </div>

                      </div>
                    </b-collapse>
                  </div>
                  <div class="card card-custom bg-white mb-3 shadow-sm">
                    <b-button v-b-toggle.collapse-4 class="btn btn-block btn-white text-primary p-10 text-left">
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                       <i class="fas fa-chevron-right text-primary"></i>
                      </span>
                      <span class="h4 ml-5 font-size-bolder">{{$t("FAQCUSTOM.FAQ1")}}</span>
                    </b-button>
                    <b-collapse id="collapse-4">
                      <div class="card-body">
                        <div class="row px-5">
                          <div class="col-lg-12 col-sm-12 lead" v-html="$t('FAQCUSTOM.R1')">

                          </div>
                        </div>

                      </div>
                    </b-collapse>
                  </div>
                  <div class="card card-custom bg-white mb-3 shadow-sm">
                    <b-button v-b-toggle.collapse-3 class="btn btn-block btn-white text-primary p-10 text-left">
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                       <i class="fas fa-chevron-right text-primary"></i>
                      </span>
                      <span class="h4 ml-5 font-size-bolder">{{$t("FAQCUSTOM.FAQ2")}}</span>
                    </b-button>
                    <b-collapse id="collapse-3">
                      <div class="card-body">
                        <div class="row px-5">
                          <div class="col-lg-12 col-sm-12 lead" v-html="$t('FAQCUSTOM.R2')">
                          </div>
                        </div>

                      </div>
                    </b-collapse>
                  </div>

                  <div class="card card-custom bg-white mb-3 shadow-sm">
                    <b-button v-b-toggle.collapse-5 class="btn btn-block btn-white text-primary p-10 text-left">
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                       <i class="fas fa-chevron-right text-primary"></i>
                      </span>
                      <span class="h4 ml-5 font-size-bolder">{{$t("FAQCUSTOM.FAQ3")}}</span>
                    </b-button>
                    <b-collapse id="collapse-5">
                      <div class="card-body">
                        <div class="row px-5">
                          <div class="col-lg-12 col-sm-12 lead" v-html="$t('FAQCUSTOM.R3')">
                          </div>
                        </div>

                      </div>
                    </b-collapse>
                  </div>
                  <div class="card card-custom bg-white mb-3 shadow-sm">
                    <b-button v-b-toggle.collapse-6 class="btn btn-block btn-white text-primary p-10 text-left">
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                       <i class="fas fa-chevron-right text-primary"></i>
                      </span>
                      <span class="h4 ml-5 font-size-bolder"> {{$t("FAQCUSTOM.FAQ4")}}</span>
                    </b-button>
                    <b-collapse id="collapse-6">
                      <div class="card-body">
                        <div class="row px-5">
                          <div class="col-lg-12 col-sm-12 lead" v-html="$t('FAQCUSTOM.R4')">

                          </div>
                        </div>

                      </div>
                    </b-collapse>
                  </div>
                  <div class="card card-custom bg-white mb-3 shadow-sm">
                    <b-button v-b-toggle.collapse-7 class="btn btn-block btn-white text-primary p-10 text-left">
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                       <i class="fas fa-chevron-right text-primary"></i>
                      </span>
                      <span class="h4 ml-5 font-size-bolder">{{$t("FAQCUSTOM.FAQ5")}}</span>
                    </b-button>
                    <b-collapse id="collapse-7">
                      <div class="card-body">
                        <div class="row px-5">
                          <div class="col-lg-12 col-sm-12 lead" v-html="$t('FAQCUSTOM.R5')">

                          </div>
                        </div>

                      </div>
                    </b-collapse>
                  </div>
                  <div class="card card-custom bg-white mb-3 shadow-sm">
                    <b-button v-b-toggle.collapse-8 class="btn btn-block btn-white text-primary p-10 text-left">
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                       <i class="fas fa-chevron-right text-primary"></i>
                      </span>
                      <span class="h4 ml-5 font-size-bolder">{{$t("FAQCUSTOM.FAQ6")}}</span>
                    </b-button>
                    <b-collapse id="collapse-8">
                      <div class="card-body">
                        <div class="row px-5">
                          <div class="col-lg-12 col-sm-12 lead" v-html="$t('FAQCUSTOM.R6')">
                          </div>
                        </div>

                      </div>
                    </b-collapse>
                  </div>
                  <div class="card card-custom bg-white mb-3 shadow-sm">
                    <b-button v-b-toggle.collapse-9 class="btn btn-block btn-white text-primary p-10 text-left">
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                       <i class="fas fa-chevron-right text-primary"></i>
                      </span>
                      <span class="h4 ml-5 font-size-bolder">{{$t("FAQCUSTOM.FAQ7")}}</span>
                    </b-button>
                    <b-collapse id="collapse-9">
                      <div class="card-body">
                        <div class="row px-5">
                          <div class="col-lg-12 col-sm-12 lead" v-html="$t('FAQCUSTOM.R7')">

                          </div>
                        </div>

                      </div>
                    </b-collapse>
                  </div>
                   <div class="card card-custom bg-white mb-3 shadow-sm">
                    <b-button v-b-toggle.collapse-10 class="btn btn-block btn-white text-primary p-10 text-left">
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                       <i class="fas fa-chevron-right text-primary"></i>
                      </span>
                      <span class="h4 ml-5 font-size-bolder">{{$t("FAQCUSTOM.FAQ8")}}</span>
                    </b-button>
                    <b-collapse id="collapse-10">
                      <div class="card-body">
                        <div class="row px-5">
                          <div class="col-lg-12 col-sm-12 lead" v-html="$t('FAQCUSTOM.R8')">

                          </div>
                        </div>

                      </div>
                    </b-collapse>
                  </div>
                  <div class="card card-custom bg-white mb-3 shadow-sm">
                    <b-button v-b-toggle.collapse-11 class="btn btn-block btn-white text-primary p-10 text-left">
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                       <i class="fas fa-chevron-right text-primary"></i>
                      </span>
                      <span class="h4 ml-5 font-size-bolder">{{$t("FAQCUSTOM.FAQ9")}}</span>
                    </b-button>
                    <b-collapse id="collapse-11">
                      <div class="card-body">
                        <div class="row px-5">
                          <div class="col-lg-12 col-sm-12 lead" v-html="$t('FAQCUSTOM.R9')">

                          </div>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                  <div class="card card-custom bg-white mb-3 shadow-sm">
                    <b-button v-b-toggle.collapse-12 class="btn btn-block btn-white text-primary p-10 text-left">
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                       <i class="fas fa-chevron-right text-primary"></i>
                      </span>
                      <span class="h4 ml-5 font-size-bolder">{{$t("FAQCUSTOM.FAQ10")}}</span>
                    </b-button>
                    <b-collapse id="collapse-12">
                      <div class="card-body">
                        <div class="row px-5">
                          <div class="col-lg-12 col-sm-12 lead" v-html="$t('FAQCUSTOM.R10')">

                          </div>
                        </div>

                      </div>
                    </b-collapse>
                  </div>
                  <div class="card card-custom bg-white mb-3 shadow-sm">
                    <b-button v-b-toggle.collapse-13 class="btn btn-block btn-white text-primary p-10 text-left">
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                       <i class="fas fa-chevron-right text-primary"></i>
                      </span>
                      <span class="h4 ml-5 font-size-bolder">{{$t("FAQCUSTOM.FAQ11")}}</span>
                    </b-button>
                    <b-collapse id="collapse-13">
                      <div class="card-body">
                        <div class="row px-5">
                          <div class="col-lg-12 col-sm-12 lead" v-html="$t('FAQCUSTOM.R11')">

                          </div>
                        </div>

                      </div>
                    </b-collapse>
                  </div>
                  <div class="card card-custom bg-white mb-3 shadow-sm">
                    <b-button v-b-toggle.collapse-14 class="btn btn-block btn-white text-primary p-10 text-left">
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                       <i class="fas fa-chevron-right text-primary"></i>
                      </span>
                      <span class="h4 ml-5 font-size-bolder">{{$t("FAQCUSTOM.FAQ12")}}</span>
                    </b-button>
                    <b-collapse id="collapse-14">
                      <div class="card-body">
                        <div class="row px-5">
                          <div class="col-lg-12 col-sm-12 lead" v-html="$t('FAQCUSTOM.R12')">

                          </div>
                        </div>

                      </div>
                    </b-collapse>
                  </div>
                  <div class="card card-custom bg-white mb-3 shadow-sm">
                    <b-button v-b-toggle.collapse-15 class="btn btn-block btn-white text-primary p-10 text-left">
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                       <i class="fas fa-chevron-right text-primary"></i>
                      </span>
                      <span class="h4 ml-5 font-size-bolder">{{$t("FAQCUSTOM.FAQ13")}}</span>
                    </b-button>
                    <b-collapse id="collapse-15">
                      <div class="card-body">
                        <div class="row px-5">
                          <div class="col-lg-12 col-sm-12 lead" v-html="$t('FAQCUSTOM.R13')">

                          </div>
                        </div>

                      </div>
                    </b-collapse>
                  </div>
                  <div class="card card-custom bg-white mb-3 shadow-sm">
                    <b-button v-b-toggle.collapse-16 class="btn btn-block btn-white text-primary p-10 text-left">
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                       <i class="fas fa-chevron-right text-primary"></i>
                      </span>
                      <span class="h4 ml-5 font-size-bolder">{{$t("FAQCUSTOM.FAQ14")}}</span>
                    </b-button>
                    <b-collapse id="collapse-16">
                      <div class="card-body">
                        <div class="row px-5">
                          <div class="col-lg-12 col-sm-12 lead" v-html="$t('FAQCUSTOM.R14')">

                          </div>
                        </div>

                      </div>
                    </b-collapse>
                  </div>
                  <div class="card card-custom bg-white mb-3 shadow-sm">
                    <b-button v-b-toggle.collapse-17 class="btn btn-block btn-white text-primary p-10 text-left">
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                       <i class="fas fa-chevron-right text-primary"></i>
                      </span>
                      <span class="h4 ml-5 font-size-bolder">{{$t("FAQCUSTOM.FAQ15")}} </span>
                    </b-button>
                    <b-collapse id="collapse-17">
                      <div class="card-body">
                        <div class="row px-5">
                          <div class="col-lg-12 col-sm-12 lead" v-html="$t('FAQCUSTOM.R15')">

                          </div>
                        </div>

                      </div>
                    </b-collapse>
                  </div>
                  <div class="card card-custom bg-white mb-3 shadow-sm">
                    <b-button v-b-toggle.collapse-18 class="btn btn-block btn-white text-primary p-10 text-left">
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                       <i class="fas fa-chevron-right text-primary"></i>
                      </span>
                      <span class="h4 ml-5 font-size-bolder">{{$t("FAQCUSTOM.FAQ16")}} </span>
                    </b-button>
                    <b-collapse id="collapse-18">
                      <div class="card-body">
                        <div class="row px-5">
                          <div class="col-lg-12 col-sm-12 lead" v-html="$t('FAQCUSTOM.R16')">

                          </div>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                  <div class="card card-custom bg-white mb-3 shadow-sm">
                    <b-button v-b-toggle.collapse-19 class="btn btn-block btn-white text-primary p-10 text-left">
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                       <i class="fas fa-chevron-right text-primary"></i>
                      </span>
                      <span class="h4 ml-5 font-size-bolder">{{$t("FAQCUSTOM.FAQ17")}}</span>
                    </b-button>
                    <b-collapse id="collapse-19">
                      <div class="card-body">
                        <div class="row px-5">
                          <div class="col-lg-12 col-sm-12 lead" v-html="$t('FAQCUSTOM.R17')">
                          </div>
                        </div>

                      </div>
                    </b-collapse>
                  </div>

                  <div class="card card-custom bg-white mb-3 shadow-sm">
                    <b-button v-b-toggle.collapse-20 class="btn btn-block btn-white text-primary p-10 text-left">
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                       <i class="fas fa-chevron-right text-primary"></i>
                      </span>
                      <span class="h4 ml-5 font-size-bolder">{{$t("FAQCUSTOM.FAQ18")}}</span>
                    </b-button>
                    <b-collapse id="collapse-20">
                      <div class="card-body">
                        <div class="row px-5">
                          <div class="col-lg-12 col-sm-12 lead" v-html="$t('FAQCUSTOM.R18')">

                          </div>
                        </div>

                      </div>
                    </b-collapse>
                  </div>
                   <div class="card card-custom bg-white mb-3 shadow-sm">
                    <b-button v-b-toggle.collapse-21 class="btn btn-block btn-white text-primary p-10 text-left">
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                       <i class="fas fa-chevron-right text-primary"></i>
                      </span>
                      <span class="h4 ml-5 font-size-bolder">{{$t("FAQCUSTOM.FAQ19")}}</span>
                    </b-button>
                    <b-collapse id="collapse-21">
                      <div class="card-body">
                        <div class="row px-5">
                          <div class="col-lg-12 col-sm-12 lead" v-html="$t('FAQCUSTOM.R19')">

                           </div>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                  <div class="card card-custom bg-white mb-3 shadow-sm">
                    <b-button v-b-toggle.collapse-22 class="btn btn-block btn-white text-primary p-10 text-left">
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                       <i class="fas fa-chevron-right text-primary"></i>
                      </span>
                      <span class="h4 ml-5 font-size-bolder">{{$t("FAQCUSTOM.FAQ20")}}</span>
                    </b-button>
                    <b-collapse id="collapse-22">
                      <div class="card-body">
                        <div class="row px-5">
                          <div class="col-lg-12 col-sm-12 lead" v-html="$t('FAQCUSTOM.R20')">
                          </div>
                        </div>

                      </div>
                    </b-collapse>
                  </div>
                  <div class="card card-custom bg-white mb-3 shadow-sm">
                    <b-button v-b-toggle.collapse-23 class="btn btn-block btn-white text-primary p-10 text-left">
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                       <i class="fas fa-chevron-right text-primary"></i>
                      </span>
                      <span class="h4 ml-5 font-size-bolder">{{$t("FAQCUSTOM.FAQ21")}}</span>
                    </b-button>
                    <b-collapse id="collapse-23">
                      <div class="card-body">
                        <div class="row px-5">
                          <div class="col-lg-12 col-sm-12 lead" v-html="$t('FAQCUSTOM.R21')">
                          </div>
                        </div>

                      </div>
                    </b-collapse>
                  </div>
                  <div class="card card-custom bg-white mb-3 shadow-sm">
                    <b-button v-b-toggle.collapse-25 class="btn btn-block btn-white text-primary p-10 text-left">
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                       <i class="fas fa-chevron-right text-primary"></i>
                      </span>
                      <span class="h4 ml-5 font-size-bolder">{{$t("FAQCUSTOM.FAQ22")}}</span>
                    </b-button>
                    <b-collapse id="collapse-25">
                      <div class="card-body">
                        <div class="row px-5">
                          <div class="col-lg-12 col-sm-12 lead" v-html="$t('FAQCUSTOM.R22')">

                          </div>
                        </div>

                      </div>
                    </b-collapse>
                  </div>
                   <div class="card card-custom bg-white mb-3 shadow-sm">
                    <b-button v-b-toggle.collapse-26 class="btn btn-block btn-white text-primary p-10 text-left">
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                       <i class="fas fa-chevron-right text-primary"></i>
                      </span>
                      <span class="h4 ml-5 font-size-bolder">{{$t("FAQCUSTOM.FAQ23")}}</span>
                    </b-button>
                    <b-collapse id="collapse-26">
                      <div class="card-body">
                        <div class="row px-5">
                          <div class="col-lg-12 col-sm-12 lead" v-html="$t('FAQCUSTOM.R23')">

                          </div>
                        </div>

                      </div>
                    </b-collapse>
                  </div>
                   <div class="card card-custom bg-white mb-3 shadow-sm">
                    <b-button v-b-toggle.collapse-27 class="btn btn-block btn-white text-primary p-10 text-left">
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                        <i class="fas fa-chevron-right text-primary"></i>
                      </span>
                      <span class="h4 ml-5 font-size-bolder">{{$t("FAQCUSTOM.FAQ24")}}</span>
                    </b-button>
                    <b-collapse id="collapse-27">
                      <div class="card-body">
                        <div class="row px-5">
                          <div class="col-lg-12 col-sm-12 lead" v-html="$t('FAQCUSTOM.R24')">
                          </div>
                        </div>

                      </div>
                    </b-collapse>
                  </div>
                  <div class="card card-custom bg-white mb-3 shadow-sm">
                    <b-button v-b-toggle.collapse-28 class="btn btn-block btn-white text-primary p-10 text-left">
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                       <i class="fas fa-chevron-right text-primary"></i>
                      </span>
                      <span class="h4 ml-5 font-size-bolder">{{$t("FAQCUSTOM.FAQ25")}}</span>
                    </b-button>
                    <b-collapse id="collapse-28">
                      <div class="card-body">
                        <div class="row px-5">
                          <div class="col-lg-12 col-sm-12 lead" v-html="$t('FAQCUSTOM.R25')">
                          </div>
                        </div>

                      </div>
                    </b-collapse>
                  </div>
                  <div class="card card-custom bg-primary mt-10 mb-3 shadow-sm">
                    <b-button v-b-toggle.collapse-29 class="btn btn-block btn-primary text-white p-10 text-left">
                      <span class="svg-icon svg-icon-white svg-icon-2x">
                       <i class="fas fa-chevron-right text-white"></i>
                      </span>
                      <span class="h4 ml-5 font-size-bolder">{{$t("FAQCUSTOM.GLOSSARIO")}}</span>

                    </b-button>
                    <b-collapse id="collapse-29">
                      <div class="card-body bg-white">
                        <div class="row px-5">
                          <div class="col-lg-12 col-sm-12 lead" v-html="$t('FAQCUSTOM.DETTAGLIOGLOSSARIO')">
                          </div>
                        </div>

                      </div>
                    </b-collapse>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

</template>



<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
//import OrganizationMenu from "@/view/layout/common/OrganizationMenu.vue";
import { mapGetters } from "vuex";
//import Button from './vue-bootstrap/Button.vue';

export default {
  name: "faq",
  components: {
    //OrganizationMenu
    //Button
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters([
      "getOrganization"
    ])
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, []);
     //this.getOrganization();
    // console.log(this.getOrganization);
  },
  created() {

  },
  methods: {
    getOrganization() {
      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl('organizations-expanded/1', true);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint)
          .then(res => {
            this.organization = res.data;
          })
          .catch(() => {
            // .catch(({ response }) => {
            // context.commit(SET_ERROR, [response.data.error]);
            //console.log('errore');
            reject(0);
          });
      });
    }
  }
}
</script>

<style></style>
